import { useEffect } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import tenantConfiguration from "../../../TenantVariables"
import ForgotPassword from "./components/ForgotPassword"
import Login from "./components/Login"
import ResetPassword from "./components/resetPassword"
import { BG, LOGO } from "../../../path"


const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${BG})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20'>
        <a href='javascript:void(0)' className='mb-12'>

          <img alt='Logo' src={LOGO} className='logo' />
        </a>
        <div className='w-lg-470px bg-white rounded shadow-sm p-7 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
      <Route path="*" element={<Navigate to={"/auth"} />} />
    </Route>
  </Routes>
)

export { AuthPage }
