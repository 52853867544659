import {useState} from 'react'
import {InfoWindow, Marker} from 'react-google-maps'
import tenantConfiguration from '../../../../TenantVariables'
import {CUSTOMER_PIN} from '../../../../path'

export const CustomerMarker = ({customerLocation}: any) => {
  const [displayInfo, setDisplayInfo] = useState(false)

  return (
    <Marker
      position={{lat: customerLocation.latitude, lng: customerLocation.longitude}}
      icon={{
        url: CUSTOMER_PIN,
        anchor: new google.maps.Point(17, 46),
        scaledSize: new google.maps.Size(50, 50),
      }}
      onClick={() => {
        displayInfo && setDisplayInfo(true)
      }}
      onMouseOut={() => {
        setTimeout(() => {
          setDisplayInfo(false)
        }, 2000)
      }}
    >
      {displayInfo && (
        <InfoWindow>
          <p style={{fontWeight: 'bolder'}}>{customerLocation.info}</p>
        </InfoWindow>
      )}
    </Marker>
  )
}
