/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import tenantConfiguration from '../../../TenantVariables'
import {checkRoleName, checkUserType, roleAndPermission} from '../../../_eaFruitsDms/helpers'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getUserType} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import LODashboard from './LODashboard'
import OODashboard from './OODashboard'
import SADashboard from './SADashboard'

import {useNavigate} from 'react-router-dom'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {
  financeManager,
  generalManager,
  logisticsManager,
  operationsManager,
  salesManager,
} from '../../../_eaFruitsDms/utils/roleNames'
import AMDashboard from './AMDashboard'
import StaticDashboard from './staticDashboard'
import SMDashboard from './SMDashboard'
import TSDashboard from './TSDashboard'
import {IC_LG_ALERT_YELLOW_2X} from '../../../path'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Dashboard',
  }

  const navigate = useNavigate()

  useEffect(() => {
    navigate('/dashboard')
  }, [])

  return (
    <>
      <EATitle title='Dashboard' />
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({id: 'Dashboard'})}</PageTitle>

      {localStorage.getItem('user_type') === 'SA' && (
        <>
          <SADashboard />
        </>
      )}

      {checkUserType('wm') && (
        <>
          {checkRoleName(generalManager) && <SADashboard />}
          {checkRoleName(operationsManager) && <OODashboard />}
          {checkRoleName(financeManager) && <AMDashboard />}
          {checkRoleName(salesManager) && <SMDashboard />}
          {checkRoleName(logisticsManager) && <LODashboard />}
          {!checkRoleName([
            generalManager,
            operationsManager,
            financeManager,
            salesManager,
            logisticsManager,
          ]) && (
            <>
              <div className='overlay-blur'>
                <div className='text-center'>
                  <img alt='Logo' className='h-50px logo mb-4' src={IC_LG_ALERT_YELLOW_2X} />
                  <p>
                    The dashboard is under development! <br />
                    Please navigate between the functional modules through the Menu!
                  </p>
                </div>
              </div>
              <StaticDashboard />
            </>
          )}
        </>
      )}

      {localStorage.getItem('user_type') === 'OO' && (
        <>
          <OODashboard />
        </>
      )}
      {checkUserType('ts') && (
        <>
          <TSDashboard />
        </>
      )}
      {checkUserType('lo') && (
        <>
          <LODashboard />
        </>
      )}
      {localStorage.getItem('user_type') === 'AM' && (
        <>
          {/* <div className='overlay-blur'>
            <div className='text-center'>
              <img
                alt='Logo'
                className='h-50px logo mb-4'
                src={`${tenantConfiguration.imageBaseUrl}ic_lg_alert_yellow@2x.png`}
              />
              <p>
                The dashboard is under development! <br />
                Please navigate between the functional modules through the Menu!
              </p>
            </div>
          </div> */}
          <AMDashboard />
        </>
      )}
    </>
  )
}

export {DashboardWrapper}
