import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  fetchAsyncDropdown,
  formDataConverter,
  isModalValidation,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../../_eaFruitsDms/helpers'
import ShowingEntries from '../../../../../_eaFruitsDms/helpers/components/ShowingEntries'
import {getRequest, patchRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import Imagecropper from '../../../../../_eaFruitsDms/layout/components/forms/Cropper'
import IsLoader from '../../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import {DEFAULT_BRAND_IMAGE} from '../../../../../path'

const CmsTeamMember = () => {
  const initialPageState = {
    search: '',
    limit: 10,
    offset: '',
    list: [],
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: '',
    selected: 0,
  }
  const initialTeamMember = {
    first_name: '',
    last_name: '',
    sub_title: '',
    select_team: '',
    short_bio: '',
    profile_link: '',
    profile_picture: '',
  }
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [pageState, setPageState] = useState(initialPageState)
  const [requestHandler, setRequest] = useState('Add')
  const [name, setName] = useState('')
  const [disableBtn, setDisable] = useState(false)
  const [isLoading, setIsLoading] = useState('Submit')
  const [imageSize, setImageSize] = useState('')
  const [cropData, setCropData] = useState(defaultSrc)
  const [teamMember, setTeamMember] = useState(initialTeamMember)
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [teamList, setTeamList] = useState([])
  const [selectTeam, setSelectTeam] = useState<any>('')
  const [imageValidation, setImageValidation] = useState<boolean>(true)
  const [validation, setValidation] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [id, setId] = useState('')
  const [invalidUrl, setInvalidUrl] = useState('')

  const getCmsTeamMemberList = async () => {
    // Develop BY: Kashyap
    // Description: get List Details
    const url =
      apiBaseURL() +
      'ourteam/teamMembers/list?' +
      `ordering=-updated_date&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${pageState.search}`
    const response = await getRequest(url, true)
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
      isLoading: response ? false : true,
    })
  }

  const checkUrl = (userInput: any) => {
    if (/(http(s?)):\/\//i.test(userInput)) setInvalidUrl('')
    else setInvalidUrl('Please enter valid external profile link.')
  }

  const onSubmit = async () => {
    setValidation(true)
    // teamMember.profile_link !== null && checkUrl(teamMember.profile_link)
    if (isModalValidation(teamMember).length === 0 && imageValidation && invalidUrl === '') {
      setName(teamMember.first_name + ' ' + teamMember.last_name)
      setDisable(true)
      setIsLoading('Please Wait...')
      let url = apiBaseURL() + 'ourteam/teamMembers/create'
      let editUrl = apiBaseURL() + 'ourteam/teamMembers/update/' + id + '/'

      let removeKey = []
      if (typeof teamMember.profile_picture === 'string' || teamMember.profile_picture === null) {
        removeKey.push('profile_picture')
      }

      // Develop BY: Kashyap
      // Description: make the requestHandler for post and patch request with same code.
      const response =
        requestHandler === 'Add'
          ? await postRequest(url, formDataConverter(teamMember, []), true)
          : await patchRequest(editUrl, formDataConverter(teamMember, removeKey), true)

      let successArray = [201, 200]
      let failArray = [400, 500]
      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail()
    }
  }

  const responseSuccess = () => {
    getCmsTeamMemberList()
    setShowAlert(true)
    setIsLoading('Submit')
    setDisable(false)
  }

  const responseFail = () => {
    setIsLoading('Submit')
    setDisable(false)
  }

  const cancleFunction = () => {
    setValidation(false)
    setImageValidation(true)
    setImageSize('')
    setSelectTeam('')
    setTeamMember(initialTeamMember)
    setOffset(0)
    setIsLoading('Submit')
    setCropData(defaultSrc)
    setDisable(false)
    setInvalidUrl('')
  }

  const getImage = (val: any) => {
    modalHandler(val, 'image')
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setImageSize('text-danger')
            setImageValidation(false)
          } else {
            setImageSize('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const modalHandler = (event: any, key: any) => {
    // Develop BY: Kashyap
    // Description: handling modal value when user add details

    key === 'input' && setTeamMember({...teamMember, [event.target.name]: event.target.value})
    key === 'team' && setTeamMember({...teamMember, select_team: event.value})
    key === 'image' && setTeamMember({...teamMember, profile_picture: event})
    if (key === 'edit') {
      setRequest('Edit')
      setId(event.id)
      setSelectTeam({label: event.select_team.title, value: event.select_team.id})
      setTeamMember({
        ...teamMember,
        first_name: event.firstname !== null && event.first_name,
        last_name: event.lastname !== null && event.last_name,
        sub_title: event.sub_title !== null && event.sub_title,
        select_team: event.select_team !== null && event.select_team.id,
        short_bio: event.short_bio !== null && event.short_bio,
        profile_link: event.profile_link !== null ? event.profile_link : '',
        profile_picture: event.profile_picture,
      })
      event.profile_picture ? setCropData(event.profile_picture) : setCropData(defaultSrc)
    }
  }

  const fetchTeam = (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      teamList,
      setTeamList,
      'ourteam/teams/list?ordering=title',
      search,
      setDropDownSearch,
      'title',
      false
    )
  }

  useEffect(() => {
    setOffset(0)
  }, [dropDownSearch])

  useEffect(() => {
    getCmsTeamMemberList()
  }, [pageState.currentOffset, pageState.search])

  return (
    <>
      <EATitle title='Team Members - ' />
      <PageTitle breadcrumbs={[{title: 'CMS - Our Team'}]}>{'Team Members'}</PageTitle>

      <EaAlertSuccess
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={
          requestHandler === 'Add'
            ? 'CMS.TEAM.MEMBER.ADD'
            : requestHandler === 'Edit' && 'CMS.TEAM.MEMBER.UPDATE'
        }
        name={name}
      />

      <div className={`card`}>
        {pageState.isLoading && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar newSearch={true} setResponse={setPageState} />
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              <EaButton
                isModal={true}
                onClick={() => setRequest('Add')}
                dataTarget='#ea_modal_create_blogauthor'
                btnName='Add Team Member'
              />
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          {pageState.list.length === 0 ? (
            <NoRecords />
          ) : (
            <TeamTable
              list={pageState.list}
              getPropsEdit={(item: any) => modalHandler(item, 'edit')}
            />
          )}
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            <ShowingEntries response={pageState} />
          </div>
          <div className='col'>
            <CustomPagination newProp={true} pageState={pageState} setPageState={setPageState} />
          </div>
        </div>
      </div>

      <EaModal
        actionBtnName={isLoading}
        btnDisabled={disableBtn}
        cancel={cancleFunction}
        onSubmit={onSubmit}
        id='ea_modal_create_blogauthor'
        modalTitle={`${requestHandler} Team Member`}
      >
        <div className='row'>
          <div className='col-6'>
            <div className='mb-3'>
              <label className='form-label'>First Name</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  validation && teamMember.first_name === '' ? 'is-invalid' : ''
                )}
                placeholder='Type here...'
                value={teamMember.first_name}
                name='first_name'
                onChange={(e) => modalHandler(e, 'input')}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='mb-3'>
              <label className='form-label'>Last Name</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  validation && teamMember.last_name === '' ? 'is-invalid' : ''
                )}
                placeholder='Type here...'
                value={teamMember.last_name}
                name='last_name'
                onChange={(e) => modalHandler(e, 'input')}
              />
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <label className='form-label'>Sub Title</label>
          <input
            type='text'
            className={clsx(
              'form-control',
              validation && teamMember.sub_title === '' ? 'is-invalid' : ''
            )}
            onChange={(e) => modalHandler(e, 'input')}
            value={teamMember.sub_title}
            name='sub_title'
            placeholder='Type here...'
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>Select Team</label>
          <AsyncPaginate
            loadOptions={fetchTeam}
            isSearchable
            className={`react-select-container  ${
              validation && teamMember.select_team === '' ? 'is-invalid' : ''
            }`}
            classNamePrefix='react-select'
            name='select_team'
            onChange={(e: any) => {
              modalHandler(e, 'team')
              setSelectTeam(e)
            }}
            value={selectTeam}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>External Profile Link</label>
          <input
            type='text'
            className={clsx(
              'form-control',
              validation && teamMember.profile_link === '' ? 'is-invalid' : ''
            )}
            placeholder='Type here...'
            onBlur={() => {
              checkUrl(teamMember.profile_link)
            }}
            onChange={(e) => {
              modalHandler(e, 'input')
            }}
            value={teamMember.profile_link}
            name='profile_link'
          />
        </div>
        <div className='mb-3'>{invalidUrl && <ErrorHandler errorMessage={invalidUrl} />}</div>

        <div className='mb-4'>
          <label className='form-label'>Short Bio</label>
          <textarea
            className={`form-control  ${
              validation && teamMember.short_bio === '' ? 'is-invalid' : ''
            }`}
            rows={3}
            placeholder='Type here…'
            onChange={(e) => modalHandler(e, 'input')}
            value={teamMember.short_bio}
            name='short_bio'
          ></textarea>
        </div>

        <div>
          <Imagecropper
            title='Team Image'
            getImage={getImage}
            onImageChange={onImageChange}
            imageRequire={
              validation && teamMember.profile_picture === '' ? 'border-danger rounded' : ''
            }
            imageSize={imageSize}
            cropData={cropData}
            setCrop={setCropData}
          />
        </div>
      </EaModal>
    </>
  )
}

export default CmsTeamMember

const TeamTable = (props: any) => {
  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered align-middle gs-0 gy-2'>
        <thead>
          <tr className=' text-muted fw-500'>
            <th className='min-w-120px py-2'>Team Member Name</th>
            <th className='min-w-120px py-2'>Sub Title</th>
            <th className='min-w-120px py-2'>Team</th>
            <th className='min-w-80px action-th py-2'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.list.length !== 0 &&
            props.list.map((item: any) => (
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-28px me-2'>
                      <span className='symbol-label'>
                        {item.profile_thumbnail === null ? (
                          <img
                            alt='group image'
                            className='h-35px rounded-1'
                            src={DEFAULT_BRAND_IMAGE}
                          />
                        ) : (
                          <img
                            src={item.profile_thumbnail}
                            className='h-40 rounded-1'
                            alt=''
                            style={{
                              height: '100%',
                              width: '100%',
                            }}
                          />
                        )}
                      </span>
                    </div>
                    <span>{item.first_name + ' ' + item.last_name}</span>
                  </div>
                </td>
                <td>{item.sub_title ? item.sub_title : '-'}</td>
                <td>{item.select_team ? item.select_team.title : '-'}</td>
                <td className='text-end'>
                  <a
                    onClick={() => props.getPropsEdit(item)}
                    href='#ea_modal_create_blogauthor'
                    data-bs-toggle='modal'
                    data-bs-target='#ea_modal_create_blogauthor'
                    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
