import React from 'react'
import ThreeDotsLoader from './threeDotsLoader'

const IsLoader = () => {
  return (
    <div
      className='text-center mb-5'
      style={{
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '65px',
        bottom: 0,
        width: '100% !important',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.7)',
        height: '100% !important',
        zIndex: 99,
      }}
    >
      <ThreeDotsLoader />
    </div>
  )
}

export default IsLoader
