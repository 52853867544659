import {NO_RECORD_IMAGE} from '../../../../path'

const NoRecords = () => {
  return (
    <>
      <div className='text-center mb-5'>
        <img src={NO_RECORD_IMAGE} alt='eaf' />

        <p className='fw-600'>No records found !</p>
      </div>
    </>
  )
}

export default NoRecords
