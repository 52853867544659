import tenantConfiguration from './TenantVariables'

const configUrl = tenantConfiguration.imageBaseUrl

const LOGO = require(`${configUrl}tenant.png`).default
const BG = require(`${configUrl}auth-bg.png`).default
const CUSTOMER_PIN = require(`${configUrl}customer-pin.png`).default
const DISTRIBUTION_CENTER_PIN = require(`${configUrl}distribution-center-pin.png`).default
const IC_LG_ALERT_YELLOW_2X = require(`${configUrl}ic_lg_alert_yellow@2x.png`).default
const IC_LG_ALERT_PHOTO_3X = require(`${configUrl}ic_lg_photo_pin@3x.png`).default
const IC_MAP_1 = require(`${configUrl}ic-map-1.svg`).default
const IC_MAP = require(`${configUrl}ic-map.svg`).default
const IC_TRUCK = require(`${configUrl}ic-truck.svg`).default
const LOGO_DARK = require(`${configUrl}logo-dark.png`).default
const LOGO_LIGHT = require(`${configUrl}logo-light.png`).default
const WAREHOUSE_PIN = require(`${configUrl}warehouse-pin.png`).default
const FETCHING_IMAGE = require(`${configUrl}img-fetching-data.png`).default
const NO_RECORD_IMAGE = require(`${configUrl}error_graphic_one.png`).default
const DEFAULT_USER_IMAGE = require(`${configUrl}img_user.png`).default
const DEFAULT_BRAND_IMAGE = require(`${configUrl}img_brand.png`).default
const FAVICON = require(`${configUrl}favicon.ico`).default

export {
  LOGO,
  BG,
  CUSTOMER_PIN,
  DISTRIBUTION_CENTER_PIN,
  IC_LG_ALERT_YELLOW_2X,
  IC_LG_ALERT_PHOTO_3X,
  IC_MAP_1,
  IC_MAP,
  IC_TRUCK,
  LOGO_DARK,
  LOGO_LIGHT,
  WAREHOUSE_PIN,
  FETCHING_IMAGE,
  NO_RECORD_IMAGE,
  DEFAULT_USER_IMAGE,
  DEFAULT_BRAND_IMAGE,
  FAVICON,
}
