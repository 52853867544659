import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../../_eaFruitsDms/layout/core'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {DEFAULT_BRAND_IMAGE} from '../../../../path'

type Props = {
  className: string
}

const Products: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const masterHeaderTitle = {
    title: 'Categories',
    subTitle: 'CMS',
  }

  // all states

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [count, setCount] = useState('1')
  const [timer, setTimer] = useState<number>(0)
  const [search, setSearch] = useState<any>('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [products, setProducts] = useState([])
  const [messageHandler, setMessageHandler] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500)

  const cmsProducts: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    localStorage.removeItem('ProductsId')
    if (localStorage.getItem('type')) {
      // setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => fetchProducts().then(), timer)
  }, [currentOffset, debounceSearch, count, products.length])

  const fetchPermission = async () => {
    let res = await checkPermissions('cms')
    setPermission(res)
  }

  const fetchProducts = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}cms/product/?limit=${limit}&search=${debounceSearch}&offset=${currentOffset}&ordering=-updated_date`
    const response = await getRequest(API, true)
    setProducts(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }
  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  return (
    <>
      <EATitle title='Products for Website - ' />
      <PageTitle breadcrumbs={cmsProducts}>
        {intl.formatMessage({id: 'Products for Website'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={FormatMessage('CMS_PRODUCTS_GROUP.UPDATE')}
      />
      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleChangeSearch} />
            {/* } */}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {products.length == 0 ? (
              <NoRecords />
            ) : (
              <>
                <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                  <thead>
                    <tr className=' text-muted fw-500'>
                      <th className='min-w-120px py-2'>Product Group Name</th>
                      <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.length > 0 &&
                      products.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-28px me-2'>
                                    <span className='symbol-label'>
                                      {item.cover_image == null ? (
                                        <img
                                          alt='group image'
                                          className='h-35px rounded-1'
                                          src={DEFAULT_BRAND_IMAGE}
                                        />
                                      ) : (
                                        <img
                                          src={item.cover_image}
                                          className='h-40 rounded-1'
                                          alt=''
                                          style={{width: '100%', height: '100%'}}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div className='mb-4'>
                                    <span>{item.product_name}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    onClick={() => {
                                      localStorage.setItem('productsId', item.id)
                                      navigate('edit-products')
                                    }}
                                    // href='#'
                                    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${products.length == 0 ? 0 : selected * limit + 1} to ${
              products.length == 0 ? 0 : products.length < 10 ? count : (selected + 1) * 10
            } of ${products.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={count}
              selected={selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Products
