import {GoogleMap, KmlLayer, Marker, withGoogleMap, withScriptjs} from 'react-google-maps'
import tenantConfiguration from '../../../../TenantVariables'
import {DISTRIBUTION_CENTER_PIN, WAREHOUSE_PIN} from '../../../../path'

const MapWithAKmlLayer = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap defaultZoom={4} defaultCenter={props.center} center={props.center} zoom={props.zoom}>
      {/* load multipal KML files */}
      {props.KMLfiles.length !== 0 &&
        props.KMLfiles.map((kmlUrl: any) => {
          return <KmlLayer url={kmlUrl} options={{preserveViewport: true}} />
        })}

      <Marker
        position={props.center}
        icon={{
          url:
            props.warehouseType === 'DistributionCenter' ? DISTRIBUTION_CENTER_PIN : WAREHOUSE_PIN,
          anchor: new google.maps.Point(17, 46),
          scaledSize: new google.maps.Size(50, 50),
        }}
      />
    </GoogleMap>
  ))
)

export default MapWithAKmlLayer
