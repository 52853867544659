import { toAbsoluteUrl } from "./_eaFruitsDms/helpers";

// Developed By: Pinak Mehta to get subdomain from the URL
export const getSubDomain = () => {
    const full = window.location.host
    const parts = full.split('.')
    const modifyUrl = parts[0].split('-')[0];
    
    return modifyUrl
}

//If Tenant details add into environment file then use that details otherwise use default configuration
const tenantConfiguration: any = {
    apiBaseUrl: (process.env[`REACT_APP_${getSubDomain()}_API_BASE_URL`]) ? process.env[`REACT_APP_${getSubDomain()}_API_BASE_URL`] : process.env.REACT_APP_API_BASE_URL,
    // imageBaseUrl: window.location.protocol + "//" + window.location.host + "/" + toAbsoluteUrl(((process.env[`REACT_APP_${getSubDomain()}_API_BASE_URL`]) ? process.env[`REACT_APP_${getSubDomain()}_IMAGE_BASE_URL`] : process.env.REACT_APP_IMAGE_BASE_URL) + ""),
    imageBaseUrl: (process.env[`REACT_APP_${getSubDomain()}_IMAGE_BASE_URL`]) ? process.env[`REACT_APP_${getSubDomain()}_IMAGE_BASE_URL`] : process.env.REACT_APP_IMAGE_BASE_URL,
    cssBaseUrl: (process.env[`REACT_APP_${getSubDomain()}_CSS_BASE_URL`]) ? process.env[`REACT_APP_${getSubDomain()}_CSS_BASE_URL`] : process.env.REACT_APP_CSS_BASE_URL,
    forgotPasswordBaseUrl: (process.env[`REACT_APP_${getSubDomain()}_FORGOT_PASSWORD_BASE_URL`]) ? process.env[`REACT_APP_${getSubDomain()}_FORGOT_PASSWORD_BASE_URL`] : process.env.REACT_APP_FORGOT_PASSWORD_BASE_URL,
    baseCountryCode: (process.env[`REACT_APP_${getSubDomain()}_BASE_COUNTRY_CODE`]) ? process.env[`REACT_APP_${getSubDomain()}_BASE_COUNTRY_CODE`] : process.env.REACT_APP_COUNTRY_CODE_BASE_URL,
    baseDateFormat: (process.env[`REACT_APP_${getSubDomain()}_BASE_DATE_FORMAT`]) ? process.env[`REACT_APP_${getSubDomain()}_BASE_DATE_FORMAT`] : process.env.REACT_APP_BASE_DATE_FORMAT,
    currencyCode: (process.env[`REACT_APP_${getSubDomain()}_CURRENCY_CODE_FORMAT`]) ? process.env[`REACT_APP_${getSubDomain()}_CURRENCY_CODE_FORMAT`] : process.env.REACT_APP_CURRENCY_CODE_FORMAT,
    timezone: (process.env[`REACT_APP_${getSubDomain()}_TIMEZONE_FORMAT`]) ? process.env[`REACT_APP_${getSubDomain()}_TIMEZONE_FORMAT`] : process.env.REACT_APP_TIMEZONE_FORMAT,
    numberFormat: (process.env[`REACT_APP_${getSubDomain()}_NUMBER_FORMAT`]) ? process.env[`REACT_APP_${getSubDomain()}_NUMBER_FORMAT`] : process.env.REACT_APP_NUMBER_FORMAT,
    limit: (process.env[`REACT_APP_${getSubDomain()}_DEFAULT_LIMIT`]) ? process.env[`REACT_APP_${getSubDomain()}_DEFAULT_LIMIT`] : process.env.REACT_APP_DEFAULT_LIMIT,
}

export default tenantConfiguration
