/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import tenantConfiguration from '../../../../TenantVariables'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'
import {LOGO_DARK, LOGO_LIGHT} from '../../../../path'

const AsideDefault: FC = (e) => {
  const {config, classes} = useLayout()
  const {aside} = config

  window.addEventListener('storage', () => {
    window.addEventListener('click', function () {
      if (localStorage.getItem('eafruitsLogin') === null) {
        window.location.reload()
      }
    })
  })

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/dashboard' style={{flex: '1'}} className='text-center'>
            <img alt='Logo' className='h-40px logo' src={LOGO_DARK} />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard' style={{flex: '1'}} className='text-center'>
            <img alt='Logo' className='h-40px logo' src={LOGO_LIGHT} />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 text-muted aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180 text-muted'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  )
}

export {AsideDefault}
